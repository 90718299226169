import { EngineStore } from '~/stores/engine'
import { ProfileStore } from '~/stores/profile'
import type { EnrollRequest } from '~/generated/api-clients-generated'
import { PlanType } from '~/generated/api-clients-generated'

export class DevModeHelpers {
  static use() {
    const { flag } = FeatureFlags.use()
    const devMode = flag<boolean>('dev-mode')
    const runtimeConfig = useRuntimeConfig()
    const devModeEnabled = computed(() => !!devMode.value && isQaOrDevelopment.value)

    const isQaOrDevelopment = computed(
      () =>
        runtimeConfig.public.sentryEnv == 'QA' || runtimeConfig.public.sentryEnv == 'Development'
    )
    const isCarrier = computed(() => runtimeConfig.public.sentryEnv == 'Carrier')

    const engine = EngineStore.use()
    const profile = ProfileStore.use()

    const isOpening = ref(false)
    const openInConnecture = async (externalId: string) => {
      if (!devModeEnabled.value || isOpening.value) return

      isOpening.value = true

      const request: EnrollRequest = {
        engineParams: engine.params,
        planId: externalId!,
        title: '',
        firstName: profile.firstName!,
        lastName: profile.lastName!,
        middleInitial: profile.middleName,
        phoneNumber: profile.mobilePhone?.number,
        email: profile.emailAddress?.address,
        mbi: profile.medicareCard?.medicareNumber,
        profileId: profile.id!
      }
      const response = await PxApi.use().createEnrollmentClient().start(request)
      const url = `${response.url}?${response.parameters}`
      console.log('URL: ', url)

      window.open(url, '_blank')
      isOpening.value = false
    }

    const HEATCarriers = [
      'anthem',
      'cigna',
      'floridablue',
      'hcsc',
      'highmark',
      'uhc',
      'upmc',
      'wellcare',
      'zing',
      'ibx',
      'medigold'
    ]
    const HEATEnabled = (carrierKey: string, planType: PlanType) => {
      return planType != PlanType.GAP && HEATCarriers.includes(carrierKey.toLowerCase())
    }

    return {
      devModeEnabled,
      isQaOrDevelopment,
      isCarrier,
      openInConnecture,
      HEATEnabled
    }
  }
}
